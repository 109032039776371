import { defineComponent, } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import DefaultProfilePic from "@images/default-profile-pic.svg";
const translationDefaults = {
    profileImageAlt: "Instructor picture",
};
export default defineComponent({
    name: 'ProfilePictureDefault',
    components: {
        DefaultProfilePic
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        return {
            profilePicThumbnail,
        };
    },
    computed: {
        customStyles() {
            return this.style;
        }
    }
});
