import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "profile-image-container" };
const _hoisted_2 = ["src", "srcset", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DefaultProfilePic = _resolveComponent("DefaultProfilePic");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.profilePhotoUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 146),
                srcset: _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 146) + ' 1x,' +
                    _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 292) + ' 2x',
                alt: _ctx.t.profileImageAlt,
                style: _normalizeStyle(_ctx.customStyles),
                class: "profile-image-img"
            }, null, 12, _hoisted_2))
            : (_openBlock(), _createBlock(_component_DefaultProfilePic, {
                key: 1,
                class: "profile-default-image-img"
            }))
    ]));
}
