export function profilePicThumbnail(url, size = 80) {
    let finalURL;
    try {
        finalURL = new URL(url);
    }
    catch {
        throw Error('Invalid URL');
    }
    if (finalURL.protocol === 'data:') {
        return finalURL.toString();
    }
    // imgIX params
    finalURL.searchParams.append('w', size.toString());
    finalURL.searchParams.append('h', size.toString());
    finalURL.searchParams.append('fit', 'crop');
    finalURL.searchParams.append('auto', 'format,compress');
    return finalURL.toString();
}
