import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { post } from '@ts/Util/http';
import { csrfToken } from '@ts/Util/security';
import { currentUserLocale } from '@ts/Util/i18n';
import ContactInstructor from '@modules/InstructorPage/ContactInstructor.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ContactInstructorHandler',
    props: {
        t: {
            type: Object,
            default: () => ({
                emailLabel: 'Email',
                nameLabel: 'Name',
                emailYourInstructor: 'Email your instructor',
                submit: 'Submit',
                emailSubmitted: 'Email sent to your instructor'
            })
        },
        showModal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        instructorPid: {
            type: String,
            required: true
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: false,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        }
    },
    emits: ['closed', 'toast:message', 'on-send-message', 'recaptcha:failure'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const toast = {
            type: 'success',
            message: props.t?.emailSubmitted,
        };
        const locale = currentUserLocale() || 'en-US';
        const closeModal = () => {
            emit('closed');
        };
        const sendMessageToInstructor = async (formData) => {
            try {
                const data = {
                    name: formData.name,
                    email: formData.email,
                    comment: formData.comment,
                    recaptchaResponse: formData.recaptchaResponse
                };
                const response = await post(`/${locale}/instructor_page/send_message/${props.instructorPid}`, csrfToken(), data);
                const responseData = await response.json();
                if (responseData.success) {
                    closeModal();
                    emit('toast:message', toast);
                }
                else {
                    emit('toast:message', {
                        type: 'danger',
                        message: responseData.message,
                    });
                }
            }
            catch (error) {
                console.error('An error occurred: ', error);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(ContactInstructor, {
                t: __props.t,
                "show-modal": __props.showModal,
                "requester-name": __props.requesterName,
                "requester-email": __props.requesterEmail,
                "instructor-pid": __props.instructorPid,
                "instructor-display-name": __props.instructorDisplayName,
                "instructor-profile-photo-url": __props.instructorProfilePhotoUrl,
                "instructor-location": __props.instructorLocation,
                "recaptcha-key": __props.recaptchaKey,
                onClosed: _cache[0] || (_cache[0] = ($event) => (closeModal())),
                "onToast:message": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('toast:message', $event))),
                onOnSendMessage: sendMessageToInstructor,
                "onRecaptcha:failure": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('recaptcha:failure', $event)))
            }, null, 8, ["t", "show-modal", "requester-name", "requester-email", "instructor-pid", "instructor-display-name", "instructor-profile-photo-url", "instructor-location", "recaptcha-key"]));
        };
    }
});
