import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "contact-form-container" };
const _hoisted_2 = { class: "contact-form" };
const _hoisted_3 = { class: "header" };
const _hoisted_4 = { class: "email-instructor" };
const _hoisted_5 = { class: "instructor-summary" };
const _hoisted_6 = { class: "instructor-summary-container" };
const _hoisted_7 = { class: "instructor-image" };
const _hoisted_8 = { class: "instructor-details" };
const _hoisted_9 = { class: "instructor-name" };
const _hoisted_10 = {
    target: "_blank",
    rel: "noopener noreferrer"
};
const _hoisted_11 = { class: "instructor-location" };
import { ref, reactive } from 'vue';
import ZButton from '@components/Core/ZButton.vue';
import ZInput from '@components/Core/ZInput.vue';
import ProfilePictureDefault from '@modules/InstructorPage/ProfilePictureDefault.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import Modal from '@components/Core/Modal.vue';
import TextArea from '@components/Core/TextArea.vue';
import GoogleRecaptcha from "@components/Core/GoogleRecaptcha.vue";
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ContactInstructor',
    props: {
        t: {
            type: Object,
            default: () => ({
                emailLabel: 'Email',
                nameLabel: 'Name',
                emailYourInstructor: 'Email your instructor',
                submit: 'Submit',
                emailSubmitted: 'Email sent to your instructor',
                requiredFieldError: "Required Field.",
                emailError: "You must enter a valid email address to continue.",
            })
        },
        showModal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        instructorPid: {
            type: String,
            required: true
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: false,
            default: ''
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        }
    },
    emits: ['closed', 'on-send-message', 'recaptcha:failure'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const props = __props;
        const formData = {
            name: props.requesterName || '',
            email: props.requesterEmail || '',
            comment: '',
            recaptchaResponse: '',
        };
        const recaptcha = ref(null);
        const state = reactive({
            errors: {
                name: '',
                email: '',
                comment: ''
            },
            loading: false,
        });
        const onBeforeSubmit = () => {
            formData.email = formData.email.trim();
            if (!allFieldsValid()) {
                return;
            }
            if (!recaptcha.value) {
                throw new Error('Google Recaptcha was not loaded.');
            }
            state.loading = true;
            recaptcha
                .value
                .execute()
                .catch((error) => {
                emit('recaptcha:failure', error);
            })
                .finally(() => state.loading = false);
        };
        const onSendMessage = (recaptchaResponse) => {
            formData.recaptchaResponse = recaptchaResponse;
            emit('on-send-message', formData);
        };
        const validateNameEntered = () => {
            if (!formData.name) {
                state.errors.name = props.t.requiredFieldError;
            }
            else {
                state.errors.name = '';
            }
        };
        const onCommentChange = () => {
            validateCommentEntered();
        };
        const validateCommentEntered = () => {
            if (!formData.comment) {
                state.errors.comment = props.t.requiredFieldError;
            }
            else {
                state.errors.comment = '';
            }
        };
        const validateEmailFormat = () => {
            const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (!emailRegex.test(formData.email)) {
                state.errors.email = props.t.emailError;
            }
            else {
                state.errors.email = '';
            }
        };
        const onEmailChange = () => {
            formData.email = formData.email.trim();
            validateEmailFormat();
        };
        const onNameChange = () => {
            validateNameEntered();
        };
        const allFieldsValid = () => {
            validateNameEntered();
            validateEmailFormat();
            validateCommentEntered();
            return Object.values(state.errors).every(fieldError => fieldError === '');
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.showModal,
                "close-when-click-on-background": "",
                "close-when-type-esc": "",
                "show-close-button": false,
                "enable-scroll": "",
                "mobile-drawer": "",
                "custom-styles": { modal: { 'padding': '2.5rem' } },
                onClosed: _cache[6] || (_cache[6] = ($event) => (emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("section", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("h2", _hoisted_4, _toDisplayString(__props.t.emailYourInstructor), 1)
                            ]),
                            _createVNode(HorizontalSeparator),
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, [
                                    _createElementVNode("div", _hoisted_7, [
                                        _createVNode(ProfilePictureDefault, {
                                            t: __props.t,
                                            "profile-photo-url": __props.instructorProfilePhotoUrl ?? '',
                                            class: "instructor-thumb-img"
                                        }, null, 8, ["t", "profile-photo-url"])
                                    ]),
                                    _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("a", _hoisted_10, _toDisplayString(__props.instructorDisplayName), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_11, _toDisplayString(__props.instructorLocation), 1)
                                    ])
                                ])
                            ]),
                            _createVNode(HorizontalSeparator),
                            _createVNode(ZInput, {
                                modelValue: formData.name,
                                "onUpdate:modelValue": [
                                    _cache[0] || (_cache[0] = ($event) => ((formData.name) = $event)),
                                    onNameChange
                                ],
                                label: props.t.nameLabel,
                                disabled: props.disabled,
                                name: "name",
                                type: "text",
                                required: "",
                                error: state.errors.name
                            }, null, 8, ["modelValue", "label", "disabled", "error"]),
                            _createVNode(ZInput, {
                                modelValue: formData.email,
                                "onUpdate:modelValue": [
                                    _cache[1] || (_cache[1] = ($event) => ((formData.email) = $event)),
                                    onEmailChange
                                ],
                                class: "contact-form__email",
                                label: props.t.emailLabel,
                                disabled: props.disabled,
                                name: "username",
                                type: "text",
                                required: "",
                                error: state.errors.email
                            }, null, 8, ["modelValue", "label", "disabled", "error"]),
                            _createVNode(TextArea, {
                                modelValue: formData.comment,
                                "onUpdate:modelValue": [
                                    _cache[2] || (_cache[2] = ($event) => ((formData.comment) = $event)),
                                    onCommentChange
                                ],
                                class: _normalizeClass(["contact-form__textarea", [state.errors.comment ? 'contact-form__textarea__error' : '']]),
                                disabled: props.disabled,
                                name: "message",
                                label: "Message",
                                required: "",
                                error: state.errors.comment
                            }, null, 8, ["modelValue", "class", "disabled", "error"]),
                            _createVNode(GoogleRecaptcha, {
                                ref_key: "recaptcha",
                                ref: recaptcha,
                                "site-key": props.recaptchaKey,
                                onLoaded: _cache[3] || (_cache[3] = ($event) => (state.loading = false)),
                                onCanceled: _cache[4] || (_cache[4] = ($event) => (state.loading = false)),
                                onFailure: _cache[5] || (_cache[5] = (error) => _ctx.$emit('recaptcha:failure', error)),
                                onSuccessful: onSendMessage
                            }, null, 8, ["site-key"]),
                            _createVNode(ZButton, {
                                class: "login-form__button-login",
                                variety: "optimus",
                                wide: "always",
                                disabled: props.disabled || state.loading,
                                onClick: onBeforeSubmit
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.submit), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["show"]));
        };
    }
});
